// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-quote-jsx": () => import("./../src/pages/quote.jsx" /* webpackChunkName: "component---src-pages-quote-jsx" */),
  "component---src-pages-services-jsx": () => import("./../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-drainlaying-jsx": () => import("./../src/pages/services/drainlaying.jsx" /* webpackChunkName: "component---src-pages-services-drainlaying-jsx" */),
  "component---src-pages-services-gasfitting-jsx": () => import("./../src/pages/services/gasfitting.jsx" /* webpackChunkName: "component---src-pages-services-gasfitting-jsx" */),
  "component---src-pages-services-plumbing-jsx": () => import("./../src/pages/services/plumbing.jsx" /* webpackChunkName: "component---src-pages-services-plumbing-jsx" */)
}

